import { FC, memo, useState } from "react";
import { CustomButton } from "../../components/customButton";
import { DefaultPopup } from "../../components/popup";
import { useMutation, useReactiveVar } from "@apollo/client";
import { DELETE_PRICE, GET_PRICES } from "../../api/graphql/prices";

import Trash from "../../assets/icons/trash.svg?react";
import { CellContext } from "@tanstack/react-table";
import { IPrice } from "../../@types/graphql";
import { toast } from "sonner";
import { chosenStore } from "../../store/stores.store";
import {
  chosenPricesCluster,
  pricesChangedRows,
} from "../../store/prices.store";

export const PricesActions: FC<CellContext<IPrice, number>> = memo(
  ({ row }) => {
    const store = useReactiveVar(chosenStore);
    const editRows = useReactiveVar(pricesChangedRows);
    const cluster = useReactiveVar(chosenPricesCluster);
    const [deleteMutation, { loading }] = useMutation(DELETE_PRICE, {
      refetchQueries: [
        {
          query: GET_PRICES,
          variables: {
            where: {
              storeId: { eq: store?.id },
              clusterId: { eq: cluster?.id },
            },
          },
        },
      ],
    });
    const [isOpen, setIsOpen] = useState(false);
    const id = row.original.id;

    return (
      <>
        <CustomButton
          leftIcon={Trash}
          className="p-6"
          color="danger"
          onClick={() => {
            setIsOpen(true);
          }}
        />
        <DefaultPopup
          title="Удаление цены"
          description="Вы действительно хотите удалить цену?"
          handleClose={() => setIsOpen(false)}
          isOpen={isOpen}
          cancelBtnProps={{ children: "Отменить", buttonView: "outline" }}
          acceptBtnProps={{
            children: "Удалить",
            color: "danger",
            disabled: loading,
            onClick: async (e) => {
              try {
                e.stopPropagation();
                await deleteMutation({ variables: { updatePrice: { id } } });
                if (id in editRows) {
                  const newRows = { ...editRows };
                  delete newRows[id];
                  pricesChangedRows(newRows);
                }
                toast.success("Цена успешно удалена");
                setIsOpen(false);
              } catch (e) {
                console.log(e);
                toast.error(e.message || "ошибка");
              }
            },
          }}
        />
      </>
    );
  },
);

PricesActions.displayName = "PricesActions";
